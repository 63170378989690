var exports = {};
exports.__esModule = true;
exports.isDef = isDef;
exports.isKorean = isKorean;
function isDef(val) {
  return val !== undefined && val !== null;
}
function isKorean(text) {
  var reg = /([(\uAC00-\uD7AF)|(\u3130-\u318F)])+/gi;
  return reg.test(text);
}
export default exports;
export const __esModule = exports.__esModule;
const _isDef = exports.isDef,
  _isKorean = exports.isKorean;
export { _isDef as isDef, _isKorean as isKorean };